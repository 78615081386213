footer {
    min-height: 80vh;
    background-color: var(--deep-blue);
    /* background-image: url('../../assets/images/Rectangle-40_1.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 6rem;
}



footer .footerWrapper {
    display: flex;
    gap: 5%;
}

footer .footerWrapper .logoCol {
    flex: 1;
}

footer .footerWrapper .menuCol {
    flex: 6;
}

footer .footerWrapper .menuCol .top {
    display: flex;
    gap: 5%;
}

footer .footerWrapper .menuCol .top .col {
    flex: 1;
    color: var(--color-white);
}

footer .footerWrapper .menuCol .top .col h4 {
    text-transform: capitalize;
    color: var(--sea-blue);
    font-size: 1.7rem;
    font-weight: 200;
}

footer .footerWrapper .menuCol .top .col .socials {
    /* width: 50%; */
    display: flex;
    gap: 5%;
    margin-bottom: 1rem;
}

footer .footerWrapper .menuCol .top .col .socials .icons .icon {
    font-size: 2rem;
    color: var(--color-white);
}

footer .footerWrapper .menuCol .top .col .search-bar form input[type="text"] {
    padding: 10px;
    font-size: 17px;
    border: 1px solid var(--color-white);
    float: left;
    width: 80%;
    height: 41px;
    background: #f1f1f1;
}

footer .footerWrapper .menuCol .top .col .search-bar form .searchButton {
    float: left;
    width: 10%;
    height: 41px;
    padding: 4.5px;
    background: var(--sea-blue);
    color: var(--color-white);
    font-size: 17px;
    border: 1px solid var(--sea-blue);
    border-left: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}

footer .footerWrapper .menuCol .top .col .search-bar form .searchButton .searchIcon {
    width: 2rem;
    height: 1.5rem;
    font-weight: bolder;
}

footer .footerWrapper .menuCol .top .col.search-bar form button::after {
    content: "";
    clear: both;
    display: table;
}

footer .footerWrapper .menuCol .bottom {
    display: flex;
    gap: 5%;
    margin: 3rem 0;
}

footer .footerWrapper .menuCol .bottom .navCol {
    flex: 1;
}

footer .footerWrapper .menuCol .bottom .navCol .menu .header h3 {
    text-transform: uppercase;
    color: var(--sea-blue);
    margin-bottom: 1rem;
}

footer .footerWrapper .menuCol .bottom .navCol .menu .menu_item {
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    text-transform: capitalize;
    font-weight: lighter;
    width: fit-content;
}

footer .footerWrapper .menuCol .bottom .navCol .menu .menu_item:hover {
    color: var(--sea-blue);
}


footer .container .footer-menu {
    border-top: solid white 2px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    text-transform: capitalize;
    font-size: 0.9rem;
    padding: 1rem 0;
}


footer .container .footer-menu {
    border-top: solid white 2px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    text-transform: capitalize;
    font-size: 0.9rem;
    padding: 1rem 0;
}

footer .container .footer-menu .copyright {
    width: 35%;
    justify-content: start;
}

footer .container .footer-menu .copyright .copy-name {
    color: var(--color-white);
}

footer .container .footer-menu .copyright .copy-name a {
    color: var(--color-white);
}

footer .container .footer-menu .copyright .top ul {
    display: flex;
    gap: 5%;
}

footer .container .footer-menu .copyright .top ul li a {
    color: var(--color-white);
}

footer .container .footer-menu .copyright .bottom li a {
    color: var(--color-white);
}

footer .container .footer-menu .menu-items {
    width: 55%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 5%;
    place-items: center;
    /* border: solid red 2px; */
}

footer .container .footer-menu .menu-items .menu_item {
    color: var(--color-white);
    /* border: solid red 2px; */
}


/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {

    footer {
        min-height: 80vh;
        background-color: var(--deep-blue);
        /* background-image: url('../../assets/images/Rectangle-40_1.png'); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding-top: 6rem;
    }

    footer .footerWrapper {


        flex-direction: column;
    }

    footer .footerWrapper .logoCol {
        flex: 1;
    }

    footer .footerWrapper .logoCol .logo img {
        width: 13rem;
    }

    footer .footerWrapper .menuCol {
        flex: 6;
    }

    footer .footerWrapper .menuCol .top {
        display: flex;
        gap: 5%;

        flex-direction: column;
    }

    footer .footerWrapper .menuCol .top .col {
        flex: 1;
        color: var(--color-white);
    }

    footer .footerWrapper .menuCol .top .col h4 {
        text-transform: capitalize;
        color: var(--sea-blue);
        font-size: 1.7rem;
        font-weight: 200;
    }

    footer .footerWrapper .menuCol .top .col .socials {
        /* width: 50%; */
        display: flex;
        gap: 5%;

        margin-top: 1rem;

    }

    footer .footerWrapper .menuCol .top .col .socials .icons .icon {
        font-size: 2rem;
        color: var(--color-white);
    }

    footer .footerWrapper .menuCol .top .col .search-bar form input[type="text"] {
        padding: 10px;
        font-size: 17px;
        border: 1px solid var(--color-white);
        float: left;
        width: 80%;
        height: 41px;
        background: #f1f1f1;
    }

    footer .footerWrapper .menuCol .top .col .search-bar form .searchButton {
        float: left;
        width: 10%;
        height: 41px;
        padding: 4.5px;
        background: var(--sea-blue);
        color: var(--color-white);
        font-size: 17px;
        border: 1px solid var(--sea-blue);
        border-left: none;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    footer .footerWrapper .menuCol .top .col .search-bar form .searchButton .searchIcon {
        width: 2rem;
        height: 1.5rem;
        font-weight: bolder;
    }

    footer .footerWrapper .menuCol .top .col.search-bar form button::after {
        content: "";
        clear: both;
        display: table;
    }

    footer .footerWrapper .menuCol .bottom {
        display: flex;
        gap: 5%;
        margin: 3rem 0;

        flex-direction: column;
    }

    footer .footerWrapper .menuCol .bottom .navCol {
        flex: 1;
    }

    footer .footerWrapper .menuCol .bottom .navCol .menu .header h3 {
        text-transform: uppercase;
        color: var(--sea-blue);

        margin-bottom: 0.5rem;

        margin-top: 2rem;
    }

    footer .footerWrapper .menuCol .bottom .navCol .menu .menu_item {
        display: flex;
        flex-direction: column;
        color: var(--color-white);
        text-transform: capitalize;
        font-weight: lighter;
        width: fit-content;
    }

    footer .footerWrapper .menuCol .bottom .navCol .menu .menu_item:hover {
        color: var(--sea-blue);
    }







    footer .container .footer-menu {
        flex-direction: column-reverse;
    }


    footer .container .footer-menu .copyright {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
    }

    footer .container .footer-menu .copyright .top ul {
        flex-direction: column;
        margin: 2rem 0;
        /* border: solid red 2px; */
    }





    footer .container .footer-menu .menu-items {
        width: 100%;
        flex-direction: column;
        gap: 1.5rem;
        align-items: flex-start;
        /* border: solid red 2px; */
    }


}