.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}

.galleryWrap .single {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  cursor: pointer;
  overflow: hidden;
}

.galleryWrap .single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.galleryWrap .single img:hover {
  transform: scale(1.07);
  opacity: 0.9;
  transition: var(--transition);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: var(--color-white);
  z-index: 9999;
  width: 2rem;
  height: 2rem;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  /* width: calc(100% - 90px);
    height: calc(100% - 90px); */
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  width: 90%;
  height: 90%;
  pointer-events: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  object-fit: contain;
}
