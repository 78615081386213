.story-services {
  background-image: url("./../../assets/images/portfolio//new/Rosy-Warner-Night-3.jpg");
}

.ourStory {
  min-height: 60vh;
  padding-top: 4rem;
}

.ourStory .container {
  display: flex;
  gap: 4%;
}

.content .header h5 {
  color: var(--deep-blue);
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
}

.content .header h1 {
  color: var(--sea-blue);
  font-weight: 300;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.ourStory .container .content .txt .bold {
  font-weight: 600;
  color: var(--deep-blue);
}

.ourStory .container .content .txt .blueColor {
  font-weight: 400;
  color: var(--sea-blue);
}

.ourStory .container .content .top iframe {
  width: 640px;
  height: 360px;
}

.ourStory .container .content .bottom .cards {
  display: flex;
  gap: 4%;
  padding-top: 2rem;
}

.ourStory .container .content .bottom .cards .card {
  flex: 1;
}

.ourStory .container .content .bottom .cards .card h3 {
  text-transform: uppercase;
  color: var(--light-blue);
}

.trust {
  min-height: 50vh;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.trust .container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trust .container .content .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.awards .container .awardRow .imgHolder img {
  width: 6rem;
}

.awards .container .awardRow .bottom {
  display: flex;
  gap: 4%;
  margin-top: 2rem;
}

.awards .container .awardRow .bottom .col {
  flex: 1;
}

.awards .container .awardRow .bottom .col .awardList .awardListItem h3 {
  color: var(--deep-blue);
  font-size: small;
  text-transform: uppercase;
}

.awards .container .accreditation .hor .imagesWrapper {
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.awards .container .accreditation .hor .imagesWrapper img {
  width: 15rem;
}

.awards .container .awardRow .bottom .col .quality .header h3 {
  color: var(--light-blue);
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.awards .container .awardRow .bottom .col .quality .header h5 {
  color: var(--deep-blue);
  margin-bottom: 0.5rem;
}

.awards .container .accreditation .qualityCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  /* border: solid red 2px; */
}

.awards .container .accreditation .quality .header {
  /* border: solid red 2px; */
  /* width: 100%; */
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.awards .container .accreditation .quality .txt {
  /* border: solid red 2px; */
  width: 100%;
  text-align: center;
}

.awards .container .topChoice {
  display: flex;
  gap: 4%;
  margin-top: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.awards .container .topChoice .colImg {
  flex: 1;
}

.awards .container .topChoice .colList {
  flex: 5;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.awards .container .topChoice .colList h3 {
  color: var(--deep-blue);
  font-size: small;
  text-transform: uppercase;
}

.qualitySection {
  min-height: 40vh;
  background-color: var(--deep-blue);
  display: flex;
  color: var(--color-white);
  margin-top: 3rem;
  gap: 4%;
}

.qualitySection .qualityImg {
  height: 40vh;
  /* width: 70%; */
  flex: 2;
  position: relative;
  background: var(--light-blue);
  /* border: solid red 2px; */

  margin: 0 0 0 -100px;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  background: var(--light-blue);
  overflow: hidden;
  position: relative;

  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}

.img {
  background: url("../../assets/images/our-story/pool-bg-home.jpg");
  position: absolute;
  top: -30px;
  left: -30px;
  right: -100px;
  bottom: -30px;
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
}

.qualitySection .textContent {
  width: 30%;
  flex: 1;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qualitySection .textContent .txt h2 {
  text-align: left;
}

.ourTeam {
  min-height: 50vh;
  margin-top: 4rem;
}

.ourTeam .container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ourTeam .container .content .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ourTeam .container .content .teamCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  gap: 2px;
  margin-top: 3rem;
  /* border: solid red 2px; */
}

.ourTeam .container .content .teamCards .card {
  width: 18rem;
  height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.ourTeam .container .content .teamCards .card .avatar {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
}

.ourTeam .container .content .teamCards .card .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ourTeam .container .content .teamCards .card .details {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border: solid red 2px; */
}

.ourTeam .container .content .teamCards .card .details .name {
  text-align: center;
  color: var(--light-blue);
  font-weight: 400;
  font-size: large;
}

.ourTeam .container .content .teamCards .card .details .position {
  text-align: center;
  color: var(--deep-blue);
}

.whereWeWork {
  min-height: 50vh;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.whereWeWork .container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.whereWeWork .container .content .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whereWeWork .container .content .map {
  margin-top: 2rem;
  /* border: solid red 2px; */
}

.whereWeWork .container .content .map img {
  width: 100%;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .ourStory .container {
    flex-direction: column;
  }

  .content .header h5 {
    color: var(--deep-blue);
    font-weight: 400;
    font-size: 1rem;
    text-transform: capitalize;
  }

  .content .header h1 {
    color: var(--sea-blue);
    font-weight: 300;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .ourStory .container .content .txt .bold {
    font-weight: 600;
    color: var(--deep-blue);
  }

  .ourStory .container .content .txt .blueColor {
    font-weight: 400;
    color: var(--sea-blue);
  }

  .ourStory .container .content .top iframe {
    width: 100%;
    height: 260px;
  }

  .ourStory .container .content .bottom .cards {
    flex-direction: column;
  }

  .ourStory .container .content .bottom .cards .card {
    flex: 1;
  }

  .ourStory .container .content .bottom .cards .card h3 {
    text-transform: uppercase;
    color: var(--light-blue);
  }

  .trust {
    min-height: 50vh;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  .trust .container .content .imgHolder img {
    width: 80%;
  }

  .awards .container .awardRow .imgHolder img {
    width: 6rem;
  }

  .awards .container .awardRow .bottom {
    flex-direction: column;
  }

  .awards .container .awardRow .bottom .col {
    flex: 1;
  }

  .awards .container .awardRow .bottom .col .awardList .awardListItem h3 {
    color: var(--deep-blue);
    font-size: small;
    text-transform: uppercase;
  }

  .awards .container .accreditation .hor .imagesWrapper {
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 1.5rem;
  }

  .awards .container .accreditation .hor .imagesWrapper img {
    width: 15rem;
  }

  .awards .container .awardRow .bottom .col .quality .header h3 {
    color: var(--light-blue);
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .awards .container .awardRow .bottom .col .quality .header h5 {
    color: var(--deep-blue);
    margin-bottom: 0.5rem;
  }

  .awards .container .awardRow .bottom .qualityCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: solid red 2px; */
  }

  .awards .container .topChoice {
    flex-direction: column;
  }

  .awards .container .topChoice .colImg {
    flex: 1;

    margin-bottom: 2rem;
  }

  .awards .container .topChoice .colImg img {
    width: 10rem;
  }

  .awards .container .topChoice .colList {
    flex: 5;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .awards .container .topChoice .colList h3 {
    color: var(--deep-blue);
    font-size: small;
    text-transform: uppercase;
  }

  .qualitySection {
    min-height: 50vh;
    background-color: var(--deep-blue);
    display: flex;
    color: var(--color-white);
    margin-top: 3rem;
    gap: 4%;

    flex-direction: column;
  }

  .qualitySection .qualityImg {
    height: 50vh;
    /* width: 70%; */
    flex: 2;
    position: relative;
    background: var(--light-blue);
    /* border: solid red 2px; */

    margin: unset;
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -o-transform: skew(0deg);
    background: var(--light-blue);
    overflow: hidden;
    position: relative;

    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }

  .img {
    background: url("../../assets/images/our-story/pool-bg-home.jpg");
    position: absolute;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    -webkit-transform: skew(00deg);
    -moz-transform: skew(00deg);
    -o-transform: skew(00deg);
  }

  .qualitySection .textContent {
    width: 100%;
    flex: 1;
    padding-left: 5;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .qualitySection .textContent .txt h2 {
    text-align: left;
  }

  .ourTeam {
    min-height: 50vh;
    margin-top: 4rem;
  }

  .ourTeam .container .content .teamCards {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8%;
    margin-top: 3rem;

    flex-direction: column;
  }

  .ourTeam .container .content .teamCards .card {
    width: 18rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: solid red 2px; */
  }

  .ourTeam .container .content .teamCards .card .avatar {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
  }

  .ourTeam .container .content .teamCards .card .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .ourTeam .container .content .teamCards .card .details {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: solid red 2px; */
  }

  .ourTeam .container .content .teamCards .card .details .name {
    text-align: center;
    color: var(--light-blue);
    font-weight: 400;
    font-size: large;
  }

  .ourTeam .container .content .teamCards .card .details .position {
    text-align: center;
    color: var(--deep-blue);
  }

  .whereWeWork {
    min-height: 50vh;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .whereWeWork .container .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: solid red 2px; */
  }

  .whereWeWork .container .content .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .whereWeWork .container .content .map {
    margin-top: 2rem;
    /* border: solid red 2px; */
  }

  .whereWeWork .container .content .map img {
    width: 100%;
  }
}
