.portfolioPage {
  background-image: url("./../../assets/images/portfolio/new/Rosy-Warner-Night-7.jpg");
}
.portfolioSection {
  margin: 4rem 0;
}

.portfolioSection .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolioSection .workImages {
  margin-top: 2rem;
}

.getQuote {
  margin-top: 4rem;
}

.getQuote .quoteWrapper {
  background-color: var(--light-blue);
  color: var(--color-white);
}

.getQuote .quoteWrapper .ver {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.getQuote .quoteWrapper .ver .formHolder {
  /* border: solid yellow 2px; */
  width: 100%;
  padding: 2%;
}

.getQuote .quoteWrapper .ver .header {
  padding: 0 8%;
}

.getQuote .quoteWrapper .ver .header h1 {
  font-weight: 600;
  font-size: 2.5rem;
  text-transform: uppercase;
}
