.getInTouch {
    background-color: var(--light-blue);
    padding: 1rem 0;
    margin-top: 4rem;

}

.getInTouch .container {
    display: flex;
    color: var(--color-white);
    gap: 4%;
}

.getInTouch .container .icon {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getInTouch .container .icon .globe {
    font-size: 4rem;
}

.getInTouch .container .txt {
    flex: 3;
}

.getInTouch .container .txt small {
    text-transform: capitalize;
    font-size: 1em;
}

.getInTouch .container .txt h3 {
    text-transform: uppercase;
    font-size: 1em;
    margin-top: 1rem;
}

.getInTouch .container .cta {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
    .sub-hero .container .heroLabel {
        background-color: var(--light-blue);
        color: var(--color-white);
        text-align: center;
        padding: 0.75rem 3rem;
        text-transform: uppercase;
    }

    .sub-hero .container .heroLabel h1 {
        font-size: 1.5rem;
    }

    .getInTouch {
        background-color: var(--light-blue);
        padding: 1rem 0;

    }

    .getInTouch .container {
        flex-direction: column;
    }

    .getInTouch .container .icon {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2rem;
    }

    .getInTouch .container .icon .globe {
        font-size: 6rem;
    }

    .getInTouch .container .txt {
        flex: 3;
        margin-bottom: 2rem;
    }

    .getInTouch .container .txt small {
        text-transform: capitalize;
        font-size: 1em;
    }

    .getInTouch .container .txt h3 {
        text-transform: uppercase;
        font-size: 1em;
        margin-top: 1rem;
    }

    .getInTouch .container .cta {
        flex: 2;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2rem;
    }
}