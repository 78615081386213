.panorama {
  min-height: 90vh;
  position: relative;
  padding-top: 5rem;
  position: relative;
}

.panorama .panoWrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  /* border: solid red 2px; */
}

.panorama .panoWrapper canvas {
  min-height: 90vh;
  /* border: solid red 2px; */
}

.panorama .panoWrapper .buttonWrapper {
  position: absolute;
  z-index: 2;
  /* top: 40%; */
  /* left: 40%; */
}

.panorama .panoWrapper .buttonWrapper .drag .icon {
  margin-right: 1rem;
  font-weight: 700;
  font-size: 2rem;
}

.panorama .panoWrapper .buttonWrapper .drag img {
  width: 3rem;
}

.panorama .panoWrapper .buttonWrapper .drag {
  background-color: var(--light-blue);
  color: var(--color-white);
  min-width: 18rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0.75rem 2rem;
  /* letter-spacing: 0.2rem; */
  /* cursor: pointer; */
  font-weight: 700;
  transition: all 400ms ease;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1%;
}

.panorama .panoWrapper .buttonWrapper:hover {
  display: none;
}

.panorama .panoWrapper .buttonWrapper:checked {
  display: none;
}

.panorama .panoWrapper .buttonWrapper .drag:hover {
  display: none;
}

.panorama .panoWrapper .buttonWrapper .drag:focus {
  display: none;
}

.outDoorParadise {
  min-height: 100vh;
  position: relative;
}

.outDoorParadise #bgVid {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.about {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
}

.about .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about .container .row {
  display: flex;
  gap: 4%;
  position: relative;
}

.about .container .row .vidWrapper {
  /* border: solid red 2px; */
  height: 30vh;
  width: 100%;
  user-select: none;
}

.about .container .row .vidWrapper video {
  height: 30vh;
  width: 100vw;
  object-fit: cover;
  user-select: none;
  /* border: solid blue 2px; */
}

.about .container .row .col {
  flex: 1;
}

.about .container .row .col .imgWrapper {
  margin-top: 5rem;
}

.about .container .row .center {
  flex: 5;
}

.about .container .row .center .contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about .container .row .center .contentWrapper .header {
  margin-bottom: 1.5rem;
}

.about .container .row .center .contentWrapper .title {
  margin-bottom: 1.5rem;
}

.services {
  min-height: 100vh;
  position: relative;
  background-color: var(--color-white);
  padding-top: 4rem;
}

.services .container {
  display: flex;
  gap: 4%;
}

.services .container .col {
  flex: 1;
}

.services .container .left .bottom {
  margin-top: 2rem;
  /* border: solid red 2px; */
}

.services .container .left .bottom .vidWrapper {
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid blue 2px; */
}

#poolVid {
  display: block;
  width: 100%;
  object-fit: contain;
  border: none !important;
  border-right: none !important;
  transform: scale(1.01);
  clip-path: inset(1px 1px);
}

video:focus {
  outline: none;
}

.services .container .right .contentWrapper .txtCard {
  padding: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.services .container .right .contentWrapper .txtCard .content h3 {
  text-transform: uppercase;
  font-weight: 800;
  color: var(--light-blue);
}

.services .container .right .contentWrapper .txtCard .content a {
  color: var(--sea-blue);
  text-transform: uppercase;
}

.services .container .right .contentWrapper .txtCard:hover {
  background-color: var(--light-blue);
  color: var(--color-white);
}

.services .container .right .contentWrapper .txtCard:hover .content h3 {
  color: var(--color-white);
}

.services .container .right .contentWrapper .txtCard:hover .content a {
  color: var(--color-white);
  text-transform: uppercase;
}

.fluid {
  /* background-color: var(--light-blue); */
  min-height: 40vh;
  margin-bottom: 4rem;
  position: relative;
  margin-top: 2rem;

  /* display: flex;
    align-items: center;
    justify-content: center; */
}

.fluid .fluidBkg {
  width: 100%;
  height: 40vh;
  z-index: 1;
  /* border: solid red 2px; */
}

.fluid .fluidBkg video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.fluid .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 2%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  margin-left: 10%;
  margin-right: 10%;
  /* border: solid red 2px; */
}

.fluid .container .counter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  /* border: solid red 2px; */
}

.fluid .container .counter .number {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
}

.fluid .container .counter .subTitle {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: lighter;
}

.projects {
  min-height: 100vh;
  position: relative;
  padding: 2rem 0;
  background-color: var(--color-white);
}

.projects .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects .container .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects .container .bottom {
  width: 100%;
}

.projects .container .bottom .info {
  display: flex;
  gap: 2%;
}

.projects .container .bottom .info .col {
  /* flex: 1; */
  width: 50%;

  /* border: solid red 2px; */
}

.projects .container .bottom .info .btn-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding-right: 4%; */
}

.projects .container .bottom .info .col p {
  /* border: solid red 2px; */
  margin: 1rem 0;
}

.clients {
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  background-color: var(--light-blue);
  position: relative;
  /* border: solid red 2px; */
}

.clients .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clients .container .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clients .container .top .header h4 {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  font-size: x-large;
  margin-bottom: 2rem;
}
.clients .container .top p {
  color: #fff;
  width: 70%;
  text-align: center;
}
.clients .container .top .buttonsContainer {
  /* border: solid red 2px; */
  width: 50%;
}
.clients .container .top .buttonsContainer .hor {
  gap: 2rem;
  /* justify-content: center !important;
  border: solid red 2px; */
}
.clients .bottom {
  background-color: var(--color-white);
  height: 100%;
  margin-top: 2rem;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  /* border: solid green 2px; */
}

/* .clients .bottom .portal {
    position: relative;
    z-index: 1;
    top: -4rem;
    width: 100%;
    height: 95vh;

} */

.clients .bottom .content {
  position: absolute;
  width: 40%;
  padding: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* top: 18rem; */
  z-index: 5;
  /* border: solid green 2px; */
}

.clients .bottom .content h1 {
  text-transform: uppercase;
  font-size: 3.5rem;
  color: var(--sea-blue);
}

.clients .bottom .content p {
  text-align: center;
}

/* .clients .bottom .vidWrapperPortal {
    border: solid green 2px;
    margin-top: -8rem;
} */

.clients .bottom .vidWrapperPortal video {
  width: 100%;
  z-index: 1;
  /* border: solid green 2px; */
}

.clients .bottom .container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.clients .bottom .container .header h1 {
  color: var(--sea-blue);
  font-size: 8rem;
  font-weight: 900;
  font-family: "Times New Roman", Times, serif;
  /* font-style: italic; */
}

/* .clients .bottom .container .testimonyWrapper {
    width: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    top: 0;

} */

.questions {
  min-height: 40vh;
  position: relative;
  background-color: var(--light-blue);
  /* border: solid var(--light-blue)2px; */
}

/* .questions .container .top {
    margin-bottom: 10rem;
} */

/* .questions .container .top .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */

/* .questions .container .top .content h1 {
    text-transform: uppercase;
    font-size: 3.5rem;
    color: var(--sea-blue);
}

.questions .container .top .content p {
    color: var(--color-white);
    width: 50%;
    text-align: center;
} */

.questions .container .bottom {
  display: flex;
  gap: 5%;
  padding: 2rem 0;
  justify-content: flex-start;
  align-items: flex-start;
}

.questions .container .bottom .cards {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  text-align: center;
}

.questions .container .bottom .cards .icon {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  margin-bottom: 2rem;
}

.questions .container .bottom .cards .detail {
  color: var(--color-white);
  text-align: center;
}

.questions .container .bottom .cards .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .panorama {
    min-height: 40vh;
    position: relative;
    padding-top: 5rem;
  }

  .panorama .panoWrapper {
    min-height: 40vh;
    /* border: solid red 2px; */
  }

  .panorama .panoWrapper canvas {
    min-height: 40vh;
    /* border: solid red 2px; */
  }

  .outDoorParadise {
    min-height: 40vh;
    position: relative;
    /* border: solid red 2px; */
  }

  .outDoorParadise #bgVid {
    position: absolute;
    left: 0;
    width: 100%;
    height: 20rem;
    object-fit: cover;
    z-index: 1;
  }

  .about {
    flex-direction: column;
  }

  .about .container .row {
    flex-direction: column;
  }

  .about .container .row .col {
    flex: 1;
  }

  .about .container .row .col .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  .about .container .row .col .imgWrapper img {
    width: 60%;
  }

  .about .container .row .center {
    flex: 5;
  }

  .about .container .row .center .contentWrapper .header {
    margin-bottom: 1rem;
  }

  .services .container {
    flex-direction: column;
  }

  .services .container .col {
    flex: 1;
  }

  .services .container .left .bottom .vidWrapper {
    height: 20rem;
    /* border: solid blue 2px; */
  }

  .services .container .right .contentWrapper .blueBkg {
    background-color: var(--light-blue);
    color: var(--color-white);
  }

  .services .container .right .contentWrapper .blueBkg .content h3 {
    color: var(--color-white);
  }

  .services .container .right .contentWrapper .blueBkg .content a {
    color: var(--color-white);
    text-transform: uppercase;
  }

  .fluid .fluidBkg {
    min-height: 70vh;

    /* border: solid red 2px; */
  }

  .fluid .container {
    margin-left: 0;
    margin-right: 0;
    /* border: solid red 2px; */

    width: 100%;
    flex-direction: column;
    padding: 2rem 0;
  }

  .fluid .container .counter .number {
    font-size: 2rem;
  }

  .projects .container .bottom .info {
    flex-direction: column;
  }

  .projects .container .bottom .info .col {
    /* flex: 1; */
    width: 100%;

    /* border: solid red 2px; */
  }

  .projects .container .bottom .info .btn-flex-end {
    margin-top: 2rem;
    justify-content: flex-start;
    /* padding-right: 4%; */
  }

  .clients {
    min-height: 50vh;
    position: relative;
    background-color: var(--light-blue);
  }

  .clients .container .top .header h4 {
    color: var(--color-white);
  }

  .clients .bottom {
    min-height: 50vh;

    /* border: solid green 2px; */
  }

  .clients .bottom .content {
    width: 100%;
    top: 13rem;
    /* border: solid green 2px; */
  }

  .clients .bottom .content h1 {
    font-size: 2rem;
  }

  .clients .bottom .vidWrapperPortal video {
    height: 70vh;
    object-fit: cover;
    /* border: solid green 2px; */
  }

  /* .clients .bottom .portal {
        position: relative;
        z-index: 1;
        top: -3rem;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        overflow: hidden;
       
    }

    .clients .bottom .portal img {
        height: 70vh;
        width: 190%;

    } */

  .clients .bottom .container .header h1 {
    font-size: 5rem;
    margin-bottom: 2rem;
    /* font-style: italic; */
  }

  .questions {
    position: relative;
    background-color: var(--light-blue);
  }

  .questions .container .bottom {
    justify-content: center;
    align-items: center;

    flex-direction: column;
  }

  .questions .container .bottom .cards {
    margin: 1rem 0;
  }
  .clients .container .top .buttonsContainer {
    width: 90%;
  }
}
