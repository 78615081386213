.thumbsWrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 2%;
}

.view {
    width: 50%;
    height: 50%;
    flex: 1;
}

.bigView {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 30rem;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid green 2px; */
    object-fit: cover;
}

.bigView img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.thumbs {
    width: 50%;
    height: 30rem;
    margin-left: auto;
    margin-right: auto;
    /* border: solid green 2px; */
    flex: 1;


}

.thumbView {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
    /* border: solid blue 2px; */
}

.mySwiper {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    /* border: solid black 2px; */
    width: 100%;
    /* display: grid;
grid-template-columns: repeat(2, 1fr); */
}

.mySwiper .thumbView {
    width: 20rem !important;
    height: 10rem;
    /* height: calc((100% - 30px) / 2) !important; */
    opacity: 0.8;
    filter: saturate(0);
    /* border: solid red 2px; */
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    filter: unset;
}

.thumbView img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
    .thumbsWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2%;
    }

    .view {
        width: 100%;
        height: 20%;
        flex: 1;
    }

    .bigView {
        text-align: center;
        font-size: 18px;
        background: #fff;
        height: 15rem;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: solid green 2px; */
        object-fit: cover;
    }

    .bigView img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }



    .thumbs {
        width: 50%;
        height: 15rem;
        margin-left: auto;
        margin-right: auto;
        /* border: solid green 2px; */
        flex: 1;


    }

    .thumbView {
        background-size: cover;
        background-position: center;
    }

    .mySwiper2 {
        height: 80%;
        width: 100%;
        /* border: solid blue 2px; */
    }

    .mySwiper {
        height: 100%;
        box-sizing: border-box;
        padding: 10px 0;
        /* border: solid black 2px; */
        width: 100%;
        /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    }

    .mySwiper .thumbView {
        width: 20rem !important;
        height: 10rem;
        /* height: calc((100% - 30px) / 2) !important; */
        opacity: 0.8;
        filter: saturate(0);
        /* border: solid red 2px; */
    }

    .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
        filter: unset;
    }

    .thumbView img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}