.formWrapper {
  /* border: solid red 2px; */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 4%;
  /* border-radius: 1rem; */
}

.formWrapper .formGroup {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  gap: 5%;
}

.formWrapper .formSingle {
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
}

.formWrapper .formGroup input {
  border: solid #f0eff2 1px;
  width: 80%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
}

.formWrapper .formSingle input {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: black;
}

.formWrapper .formSingle select {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
  color: #636364;
}

.formWrapper .formSingle select option:hover {
  color: var(--sea-blue);
}

.formWrapper textarea {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  margin-bottom: 0.2rem;
  background-color: #f0eff2;
}

.formWrapper .alertMsg {
  width: 100%;
  color: var(--light-color);
  font-size: medium;
}

.formWrapper .btn3 {
  width: 100%;
}

.formWrapper .formFooter {
  padding: 1rem 6%;
  /* border: solid red 2px; */
}

.formWrapper .formFooter p {
  width: 100%;
  color: var(--light-color);
}

.formWrapper .formFooter p a {
  font-weight: 600;
  font-style: oblique;
  color: var(--black-color);
  text-decoration: underline;
}

.formWrapper input[type="submit"] {
  border: 0;
  width: 100%;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .formWrapper {
    padding-top: 2rem;
  }

  .formWrapper .formGroup {
    flex-direction: column;
    margin-bottom: 0;
  }

  .formWrapper .formGroup input {
    width: 100%;
    margin: 1rem 0;
  }

  .formWrapper textarea {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .formWrapper .formFooter {
    padding: 1rem 0;
    /* border: solid red 2px; */
  }

  .formWrapper .formFooter p {
    text-align: left;
  }
}
