.features {
    min-height: 80vh;
    display: flex;
    gap: 4%;
    /* border: solid red 2px; */
}

.features .content {
    flex: 3;
    padding-top: 2rem;
    /* border: solid blue 2px; */
    /* width: 100%; */
    width: 50vw;
}

.features .content .textContentWrapper .header {
    display: flex;
    flex-direction: column;
}

.features .content .textContentWrapper .header h1 {
    color: var(--light-blue);
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.features .content .textContentWrapper .header h5 {
    color: var(--deep-blue);
    font-size: 1rem;
    text-transform: uppercase;
}

.features .content .textContentWrapper .vidWrapper {
    position: relative;
    margin-top: 2rem;
    transform: var(--transition);
}

.features .content .textContentWrapper .vidWrapper .ytVideo {
    width: 80%;
    position: absolute;
    z-index: 2;
    transform: var(--transition);
    /* border: solid red 2px; */
}

.features .content .textContentWrapper .vidWrapper .ytVideo iframe {
    width: 100%;
    height: 422px;
}

.features .content .textContentWrapper .vidWrapper .vidBkg {
    background-color: var(--sea-blue-variant);
    /* border: solid red 2px; */
    height: 422px;
    width: 80%;
    z-index: 1;
    right: -1rem;
    bottom: -1rem;
    position: relative;
    transform: var(--transition);
}

/* .features .content .textContentWrapper .vidWrapper:hover .vidBkg {
    right: 0;
    bottom: 0;
} */

.features .content .textContentWrapper .txtContent {
    margin-top: 2rem;
}

.features .content .textContentWrapper .featureSwipe {
    border: solid red 2px;
    width: 100%;
}

.features .content .textContentWrapper .cardContent {
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4%;
}

.features .content .textContentWrapper .cardContent .header h3 {
    text-transform: uppercase;
    color: var(--deep-blue);
    font-size: medium;
}

.features .content .textContentWrapper .cardContent .watchLink {
    margin-bottom: 2rem;
}

.features .content .textContentWrapper .cardContent .watchLink .btn-sea {
    background-color: var(--light-blue);
}

.features .content .textContentWrapper .formWrapper {
    margin-top: 2rem;
}

.features .content .textContentWrapper .formWrapper .header h4 {
    color: var(--deep-blue);
    font-size: medium;
}

.features .content .textContentWrapper .formWrapper .header h1 {
    color: var(--sea-blue);
}

.features .content .textContentWrapper .formWrapper .row {
    display: flex;
    gap: 4%;
}

.features .content .textContentWrapper .formWrapper .row .col {
    flex: 1;
}

.features .content .textContentWrapper .formWrapper .row .col .btn-sea {
    background-color: var(--light-blue);
}

.features .content .textContentWrapper .formWrapper .row .col img {
    /* border: solid red 2px; */
    width: 15rem;
}

.features .content .textContentWrapper .listWrapper .header h2 {
    text-transform: uppercase;
    color: var(--sea-blue);
    text-align: left;
    margin-bottom: 1rem;
}

.features .content .textContentWrapper .listWrapper .header h5 {
    text-transform: capitalize;
    margin-bottom: .5rem;
}

.features .content .textContentWrapper .listWrapper .list {
    margin-left: 5%;
}

.features .content .textContentWrapper .listWrapper .list ul li {
    list-style-type: square;
    list-style: none;
}

.features .content .textContentWrapper .listWrapper .list ul li::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--sea-blue);
    /* Change the color */
    font-weight: bold;
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    margin-left: -1em;

}

.features .floatingNav {
    flex: 1;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
    .features {
        flex-direction: column;
    }

    .features .content {
        flex: 1;
        width: 100%;
    }


    .features .content .textContentWrapper .vidWrapper .ytVideo {
        width: 100%;
    }

    .features .content .textContentWrapper .vidWrapper .ytVideo iframe {
        width: 100%;
        height: 350px;
    }

    .features .content .textContentWrapper .vidWrapper .vidBkg {
        height: 350px;
        width: 100%;
    }


    .features .content .textContentWrapper .cardContent {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 8rem;
    }



    .features .content .textContentWrapper .formWrapper .row {
        flex-direction: column-reverse;
    }

    .features .content .textContentWrapper .formWrapper .row .col img {
        width: 100%;
    }

    .features .content .textContentWrapper .formWrapper .row .col .btn-sea {
        margin-bottom: 3rem;
    }

    .vid-mb {
        margin-bottom: 3rem;
    }

    .features .floatingNav {
        flex: 1;
    }
}