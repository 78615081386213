.navWrapper {
    border: solid 2px var(--blue-variant);
    display: flex;
    flex-direction: column;
}

.navWrapper .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navWrapper .header h1 {
    text-transform: uppercase;
    color: var(--blue-variant);
    text-align: center;
    padding: 1.2rem 0;
}

.navWrapper a {
    text-transform: uppercase;
    color: var(--blue-variant);
    text-align: center;
    padding: 1.2rem 0;
}

.navWrapper .active {
    background-color: var(--blue-variant);
    color: var(--color-white);
    font-weight: bolder;
}

.navWrapper a:hover {
    background-color: var(--blue-variant);
    color: var(--color-white);
    font-weight: bolder;
}