.speciality {
    min-height: 90vh;
    padding-top: 2rem;
    display: flex;
    gap: 4%;
    /* border: solid red 2px; */
}

.speciality .textContentCol {
    flex: 2;
}

.speciality .imgContentCol {
    flex: 1;
    /* border: solid red 2px; */
}



.speciality .textContentCol .header {
    display: flex;
    flex-direction: column;

}

.speciality .textContentCol .header h1 {
    color: var(--light-blue);
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.speciality .textContentCol .header h5 {
    color: var(--sea-blue);
    font-size: 1rem;

}

.speciality .textContentCol .txtContent {
    margin-top: 2rem;
}

.banner {
    min-height: fit-content;
    /* border: solid red 2px; */
    margin-bottom: 3rem;
    background-color: var(--light-blue);
    position: relative;
}

.banner .background {
    background-color: var(--deep-blue);
    height: 100%;
    width: 50%;
    position: absolute;
    z-index: 1;
}

.banner .container .imgWrapper {
    position: relative;
    z-index: 3;
}

.why {
    height: fit-content;
    display: flex;
    gap: 1%;
}



.why .col {
    flex: 1;
    /* border: solid red 2px; */
}

.why .col .header h2 {
    color: var(--light-blue);
    text-transform: capitalize;
    margin-bottom: 1.2rem;
    text-align: left;
}

.why .col .list {
    margin-left: 5%;
}

.why .col .list ul li {
    list-style-type: square;
    list-style: none;
}

.why .col .list ul li::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--sea-blue);
    /* Change the color */
    font-weight: bold;
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    margin-left: -1em;

}

.yt-laptop {
    min-height: 30vh;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
    .speciality {
        flex-direction: column;
    }

    .speciality .imgContentCol {
        margin-bottom: 3rem;
    }

    .speciality .textContentCol .header {

        width: 100%;
    }

    .speciality .textContentCol .header h1 {
        font-size: 2rem;
    }

    .speciality .textContentCol .header h5 {
        font-size: 1rem;
        width: 100%;
    }



    .why {
        flex-direction: column;
    }



    .why .col {
        flex: 1;

        /* border: solid red 2px; */
    }

    .why .col .header h2 {
        color: var(--light-blue);
        text-transform: capitalize;
        margin-bottom: 1.2rem;
    }

    .why .col .list {
        margin-left: 5%;
        margin-bottom: 2rem;
    }






    .yt-laptop {
        min-height: fit-content;
    }


}