.contact-us {
  background-image: url("./../../assets/images/portfolio/new/Rosy-Warner-Night-8.jpg");
}

.contactDetails {
  min-height: 50vh;
  padding: 4rem 0;
}

.contactDetails .container {
  display: flex;
  gap: 4%;
}

.contactDetails .container .col .header {
  margin-bottom: 2rem;
}

.contactDetails .container .col .header h5 {
  text-transform: capitalize;
  color: var(--deep-blue);
}

.contactDetails .container .col .header h1 {
  color: var(--sea-blue);
  text-transform: uppercase;
  font-size: 2.5rem;
}

.contactDetails .container .col .content .contactInfo {
  display: flex;
  gap: 4%;
  min-height: 4rem;
  /* border: solid red 2px; */
}

.contactDetails .container .col .content .contactInfo .icon {
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: solid red 2px; */
}

.contactDetails .container .col .content .contactInfo .icon img {
  /* fill: var(--light-blue); */
  filter: invert(60%) sepia(25%) saturate(839%) hue-rotate(172deg)
    brightness(107%) contrast(80%);
}

.contactDetails .container .col .content .contactInfo .contactInfoDetails h5 {
  text-transform: capitalize;
}

.contactDetails
  .container
  .col
  .content
  .contactInfo
  .contactInfoDetails
  .blue {
  color: var(--light-blue);
}

.contactDetails .container .col .content .map {
  border: solid 2px var(--deep-blue);
}

.contactFormSection {
  margin: 4rem 0;
}

.contactFormSection .container {
  width: 50%;
}

.contactFormSection .container .contactForm form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  /* border-radius: 0.5rem; */
  background: transparent;
  border: 2px solid #999;
  resize: none;
  color: #000;
}

.contactFormSection .container .contactForm form button {
  z-index: 2;
  width: 100%;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .contactDetails .container {
    flex-direction: column;
  }

  .contactDetails .container .col .header h1 {
    font-size: 2rem;
  }

  .contactDetails .container .col .content .contactInfo .icon {
    width: 2rem;
    /* align-items: center; */
  }

  .contactFormSection .container {
    width: 82%;
  }
}
