.thumbViewSection .biggerPicture .swiper {
    width: 100%;
    height: 100%;
}

.thumbViewSection .biggerPicture .swiper-slide {
    text-align: center;
    font-size: 18px;
    height: 30rem;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbViewSection .biggerPicture .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border: solid cyan 2px; */
}

.thumbViewSection .biggerPicture {
    height: 80%;
    width: 100%;
}

.thumbViewSection .biggerPicture .swiper {
    width: 100%;
    height: 300px;
    margin-left: 0;
    margin-right: 0;
    /* border: solid red 2px; */
}


.thumbViewSection .biggerPicture .swiper-slide img {
    /* border: solid blue 1px; */
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbViewSection .biggerPicture .swiper-slide {
    background-size: cover;
    background-position: center;
    /* border: solid black 2px; */
}






.thumbViewSection .thumbnailPictures .swiper {
    width: 100%;
    height: 100%;
}

.thumbViewSection .thumbnailPictures .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbViewSection .thumbnailPictures .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border: solid cyan 2px; */
}

.thumbViewSection .thumbnailPictures {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.thumbViewSection .thumbnailPictures.swiper {
    width: 100%;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    /* border: solid brown 2px; */
}

.thumbViewSection .thumbnailPictures .swiper-slide {
    background-size: cover;
    background-position: center;
    /* border: solid violet 2px; */
}

.thumbViewSection .thumbnailPictures .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.thumbViewSection .thumbnailPictures .swiper-slide-thumb-active {
    opacity: 1;
}



.thumbViewSection .thumbnailPictures .swiper-slide img {
    /* border: solid red 1px; */
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}