.formsSection {
  padding-top: 8rem;
}

.formsSection .header h2 {
  text-transform: capitalize;
  color: var(--light-blue);
}
.formsSection .header p {
  text-align: center;
}
.formsSection .container {
  padding: 0 10%;
}
.formsSection .formWrapper {
  padding: 0;
  margin-bottom: 4rem;
  /* border: solid red 2px; */
}
/* .formsSection form {
  border: solid red 2px;
} */
.formsSection .btn-sea {
  width: fit-content !important;
}

.formsSection .formCheckBox {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  justify-content: unset;
  margin-bottom: 1rem;
  /* border: solid red 2px; */
}

form input[type="radio"] {
  width: 50px;
  /* border: solid red 2px; */
}
