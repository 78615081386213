.newSwipe {
    width: 82%;
}

.slider {
    padding: 32px;
    color: #fff;
}

.slider .swiper-container1 {
    width: 15rem;
    height: 500px;
    /* border: solid blue 2px; */
}

.slider .swiper-container2 {
    width: 100%;
    height: 100%;
}

.slider__flex {
    display: flex;
    align-items: flex-start;

    gap: 3%;
}

.slider__col {
    display: flex;
    flex-direction: column;
    width: 15rem;
    margin-right: 32px;

    height: 500px;
    /* border: solid red 2px; */

    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
}

.slider__prev,
.slider__next {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slider__prev:focus,
.slider__next:focus {
    outline: none;
}

.slider__thumbs {
    height: calc(400px - 96px);
    /* width: 30rem; */
}

.slider__thumbs .slider__image {
    transition: 0.25s;
    /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
    opacity: 0.5;
    /* height: 10rem; */
    /* border: solid red 2px; */
}

.slider__thumbs .slider__image:hover {
    opacity: 1;
}

.slider__thumbs .swiper-slide-thumb-active .slider__image {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
}

.slider__images {
    height: 500px;
    width: 100%;
    /* border: solid red 2px; */
}

.slider__images .slider__image img {
    transition: 3s;
}

.slider__images .slider__image:hover img {
    transform: scale(1.1);
}

.slider__image {
    width: 100%;
    height: 100%;
    /* border-radius: 30px; */
    overflow: hidden;
}

.slider__image img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

@media (max-width: 767.98px) {
    .newSwipe {
        width: 100%;
    }

    .slider__flex {
        flex-direction: column-reverse;
    }

    .slider__col {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        margin-top: 24px;
        width: 100%;
        height: 100%;
    }

    .slider__images {
        width: 100%;
        height: 300px;
    }

    /* .slider__image {
        width: 100%;
    } */

    .slider__thumbs {
        height: 100px;
        width: calc(100% - 96px);
        margin: 0 16px;
    }

    .slider__prev,
    .slider__next {
        height: auto;
        width: 32px;
    }

    .slider .swiper-container1 {
        width: 220px;
        height: 100%;

    }
}