@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --deep-blue: #113770;
  --light-blue: #366cbf;
  --sea-blue: #74ace6;
  --blue-variant: #117ba7;
  --sea-blue-variant: #b9d5f2;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --light-color: rgba(#fff, 0.7);
  --black-color: #0c031b;
  --dark-color: rgba(var(--black-color), 0.7);
  --container-lg: 82%;
  --container-md: 90%;
  --transition: all 400ms ease;
}

#root {
  white-space: pre-line;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: sans-serif, "poppins";
  line-height: 1.5;
  color: var(--dark-color);

  background-color: var(--color-white);
  /* user-select: none; */
  overflow-x: hidden;
  position: relative;

  overscroll-behavior-y: none;
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-family: poppins;
}

section h2 {
  text-align: center;
}

section p {
  text-align: center;
  width: 100%;
  margin: 0.6rem auto 2.5rem;
  text-align: left;
}

.btn-white {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: #fff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-white:hover {
  background: lighten(#fff, 8%);
  color: #000;
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}

.btn-grey {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: var(--dark-blue);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-grey:hover {
  background: lighten(#353f51, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#353f51, 0.15);
  transform: scale(0.94);
}

.btn-sea {
  min-width: 10rem;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 3rem;
  /* border-radius: 2rem; */
  background: var(--sea-blue);
  color: var(--color-white);
  font-weight: 500;
  /* letter-spacing: 0.2rem; */
  cursor: pointer;
  font-weight: 700;
  transition: all 400ms ease;
}

.btn-sea:hover {
  background: lighten(#74ace6, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#74ace6, 0.15);
  transform: scale(0.94);
}

.video__overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 165, 75, 0.1);
  background-image: linear-gradient(transparent, #00a54b);
  z-index: 2;
}

#bgVid {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

video {
  border: none !important;
  border-right: none !important;
  /* transform: scale(1.01); */
  clip-path: inset(1px 1px);
}
video:focus {
  outline: none;
}

.alertMsg {
  text-align: center;
  font-size: x-large;
  color: var(--blue-variant);
}

.header h4 {
  text-transform: lowercase;
  font-weight: 600;
  font-size: 2rem;
  color: var(--light-blue);
}

.title h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3.5rem;
  color: var(--sea-blue);
}

.hor {
  display: flex;
  gap: 4%;
}

.ver {
  flex: 1;
}

@media screen and (max-width: 600px) {
  .header h4 {
    text-transform: lowercase;
    font-weight: 600;
    font-size: x-large;
    color: var(--light-blue);
  }

  .title h1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: xx-large;
    color: var(--sea-blue);
  }

  #bgVid {
    position: absolute;
    left: 0;
    width: 100%;
    height: 40%;
    object-fit: cover;
    z-index: 1;
  }

  .hor {
    flex-direction: column;
  }
}
