.testimonials {
    padding-top: 4rem;
}

.testimonials .content .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.container.testimonials__container {
    width: 100%;
    padding-bottom: 4rem;
    min-height: 35rem;
    /* border: solid red 2px; */
}

.quotation {
    width: 100%;
    margin: 0 auto 1rem;
}

.quotation img {
    width: 2.5rem;
    filter: invert(60%) sepia(25%) saturate(839%) hue-rotate(172deg) brightness(107%) contrast(80%);
}

.testimonial {
    background: var(--deep-blue);
    text-align: left;
    padding: 2rem;
    border-radius: 0;
    user-select: none;
    min-height: 32rem;
}


.testimonial .client__review {
    color: var(--color-white);
    font-weight: 300;
    display: block;
    width: 100%;
    margin: 0.8rem auto 0;
    text-align: left;
    margin-bottom: 2rem;
}

.testimonial .client__name {
    color: var(--sea-blue);
}

.testimonial h6 {
    color: var(--color-white);
    text-transform: uppercase;
    margin-top: 1rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--deep-blue);
}


/* =========== Media Queries (Medium Screens) ========== */

@media screen and (max-width:1024px) {
    .container .testimonials__container {
        width: 60%;
    }
}


/* =========== Media Queries (Small Screens) ========== */

@media screen and (max-width:600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
    }
}