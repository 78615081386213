.navbar {
  width: 100vw;
  height: 5rem;
  position: fixed;
  z-index: 10;
  display: grid;
  place-items: center;
  background-color: var(--color-white);
  z-index: 10;
  /* box-shadow: 0 1rem 1rem rgba(#0c031b, 0.1); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.17);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .container .nav__items {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.navbar .container button {
  display: none;
}

.navbar .container .nav__items a {
  color: var(--deep-blue);
  font-weight: 400;
  font-size: 0.9rem;
  position: relative;
  text-transform: capitalize;
}

.navbar .container .nav__items a:hover {
  color: var(--light-blue);
}

.navbar .container .nav__items .active {
  color: var(--light-blue);
}

.navbar .container .nav__items .active::after {
  color: var(--light-blue);
  width: 50%;
}

.navbar .container .nav__items a::after {
  content: "";
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 25%;
  transform: translateX(-50%);
  background: var(--sea-blue-variant);
  transition: width 0.3s;
}

.navbar .container .nav__items a:hover::after {
  width: 50%;
}

.navbar .container .nav__logo img {
  width: 15rem;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .navbar {
    position: fixed;
  }

  .navbar .container button {
    display: inline-block;
    background: transparent;
    color: var(--sea-blue);
    font-size: 1.5rem;
    font-weight: 200;
    cursor: pointer;
  }

  .navbar .container .nav__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 100vh;
    position: absolute;

    left: -100%;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;

    top: 5rem;
    align-items: flex-start;
    padding-left: 20%;
  }

  .navbar .container .show__nav {
    display: flex;
    left: 0%;
  }

  .navbar .container .hide__nav {
    display: none;
  }

  .navbar .container .nav__items a {
    color: var(--white-color);
  }

  .navbar.container .nav__items li {
    display: block;
    padding: 2rem 0;
    width: 100%;
  }

  /* .navbar .container .nav__items .active::after {
        color: var(--color-joy);
        width: 50%;
    }

    .navbar .container .nav__items a:hover {
        color: var(--color-bg);
    } */

  .navbar.container .nav__logo img {
    width: 10rem;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .navbar .container .nav__logo img {
    width: 55%;
  }

  .navbar .container .nav__items .active {
    color: var(--green-color);
  }
}
