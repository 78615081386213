/* .react-photo-gallery--gallery {
  border: solid red 2px;
} */

.react-photo-gallery--gallery > div {
  /* border: solid blue 2px; */
  justify-content: center;
}

.react-photo-gallery--gallery > div img {
  /* border: solid violet 2px; */
  /* width: 19%; */
  width: 240px;
  /* width: 200px; */
}

/* react-images__view react-images__view--isModal css-1qrom1v css-1ycyyax
react-images__view-image react-images__view-image--isModal css-zjq1i3 css-1ycyyax */

.react-images__view {
  /* border: solid green 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.react-images__view-image {
  /* border: solid yellow 2px; */
  width: 60%;
}

@media screen and (max-width: 600px) {
  .react-images__view-image {
    width: 100%;
  }
}

.gallery-container {
  /* border: solid yellow 2px; */
  place-items: center;
  display: flex;
  flex-direction: column;
}

.gallery-container button {
  margin-top: 1rem;
}
