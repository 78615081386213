.sub-hero {
  padding-top: 8rem;
  min-height: 80vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-hero {
  background-image: url("./../../assets/images/portfolio/new/Rosy-Singh-40.jpg");
}

.sub-hero .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-hero .container .heroLabel {
  background-color: var(--light-blue);
  color: var(--color-white);
  text-align: center;
  padding: 0.75rem 3rem;
  text-transform: uppercase;
}

.sub-hero .container .heroLabel h1 {
  font-weight: 400;
  font-size: 2rem;
}

.products {
  padding-top: 2rem;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .sub-hero .container .heroLabel {
    background-color: var(--light-blue);
    color: var(--color-white);
    text-align: center;
    padding: 0.75rem 3rem;
    text-transform: uppercase;
  }

  .sub-hero .container .heroLabel h1 {
    font-size: 1.5rem;
  }
}
